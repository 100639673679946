import React from "react";

const SectionTitle = ({ title }) => (
  <h2 className="text-base text-lg font-bold leading-6">{title}</h2>
);

const AgreementPoint = ({ label, children }) => (
  <article>
  <p style={{ fontWeight: label ? 'normal' : 'bold' }}>{label && <span className="font-semibold">{label}:</span>} {children}</p>
</article>
);

const TermsofUse = () => {
  return (
    <main className="p-4">
    <h1 className="flex justify-center my-4 text-5xl font-semibold leading-[63.84px] text-zinc-900">Terms of Use</h1>
      <header className="mb-4">
        <h1 className="self-stretch text-sm leading-5 underline text-zinc-900">
          Welcome to PracticePartner.ai!
        </h1>
        <p>
          These Terms and Conditions govern your use of our services and outline
          important terms and responsibilities. By using PracticePartner.ai
          ("the Service"), you agree to comply with these terms. Please read
          them carefully.
        </p>
      </header>
      <section>
        <SectionTitle title="1. User Agreement and Hold Harmless" />
        <AgreementPoint label="a. User Responsibility">
          You acknowledge that PracticePartner.ai is provided as a tool for your
          convenience and guidance in launching and managing your private
          practice. While we strive to provide accurate information,
          PracticePartner.ai does not provide legal, medical, or financial
          advice. The results generated by the Service are based on general
          information and may not be factually accurate or tailored to your
          specific circumstances. It is your responsibility to verify and
          validate any information provided by the Service.
        </AgreementPoint>
        <AgreementPoint label="b. Hold Harmless Agreement">
          You agree to hold PracticePartner.ai and its affiliates, officers,
          employees, agents, and representatives harmless from any and all
          damages, claims, liabilities, costs, or expenses (including attorney's
          fees) associated with your use of the Service. You understand that it
          is your responsibility to seek legal, medical, financial, or other
          professional counsel as needed before implementing any
          recommendations, strategies, or documents generated by the Service.
        </AgreementPoint>
      </section>
      
      <section>
        <br />
        <SectionTitle title="2. No Private Health Information (PHI)" />
        <AgreementPoint label="a. Not Fit for PHI">
          PracticePartner.ai is not designed or intended to handle or accept
          private health information (PHI) and is not a HIPAA compliant system.
          You expressly acknowledge that the Service is not suitable for
          PHI-related matters.
        </AgreementPoint>
        <AgreementPoint label="b. User Agreement">
          You agree not to include any PHI, as defined by the Health Insurance
          Portability and Accountability Act (HIPAA), in questions or
          information shared with the Service. PracticePartner.ai is not
          equipped to handle PHI, and its use in the Service is strictly
          prohibited.
        </AgreementPoint>
        <AgreementPoint label="c. No Refunds">
          You acknowledge and agree that there will be no refunds for any fees
          or charges paid for the use of the Service, regardless of the
          circumstances of termination.
        </AgreementPoint>
      </section>
      <br />
      <section>
        <SectionTitle title="3. User Account and Login Information" />
        <AgreementPoint label="a. User Account">
          When you create a user account with PracticePartner.ai, you are
          responsible for maintaining the confidentiality of your account
          credentials and for all activities that occur under your account.
        </AgreementPoint>
        <AgreementPoint label="b. Login Information Sharing">
          You agree not to share your login information with any individuals or
          entities outside of your business organization. Sharing login
          credentials with unauthorized users is a violation of these Terms and
          Conditions and may result in the suspension or termination of your
          account.
        </AgreementPoint>
      </section>
      <br />
      <section>
        <SectionTitle title="4. Termination of Service" />
        <AgreementPoint label="a. Termination by User">
          You may terminate your use of the Service at any time by contacting us
          at Info@PracticePartner.ai.
        </AgreementPoint>
        <AgreementPoint label="b. Termination by PracticePartner.ai">
          PracticePartner.ai reserves the right to terminate, suspend, or
          restrict your access to the Service at our discretion, without notice,
          if you violate these Terms and Conditions or if we determine that your
          use of the Service poses a risk or harm to PracticePartner.ai or its
          users.
        </AgreementPoint>
      </section>
      <br />
      <section>
        <SectionTitle title="5. Ownership and Intellectual Property" />
        <AgreementPoint label="a. Ownership of Content">
          PracticePartner.ai retains all rights, title, and interest in the
          Service, including but not limited to all text, graphics, logos,
          images, software, and other content provided or generated by
          PracticePartner.ai. You may use the Service only as permitted by these
          Terms and Conditions.
        </AgreementPoint>
        <AgreementPoint label="b. User-Generated Content">
          Any content, materials, or information you submit to or through the
          Service remains your property. However, by submitting such content,
          you grant PracticePartner.ai a worldwide, non-exclusive, royalty-free
          license to use, reproduce, modify, adapt, publish, translate,
          distribute, and display the content as necessary to provide the
          Service and fulfill your requests.
        </AgreementPoint>
      </section>
      <br />
      <section className="mt-4">
        <p>
          <SectionTitle title="6. Privacy Policy" />
          Your use of the Service is also governed by our Privacy Policy. Please
          review it carefully to understand how we collect, use, and protect
          your personal information.
        </p>
        <br />
        <p>
          <SectionTitle title="7. Legal Jurisdiction" />
          These Terms and Conditions are governed by the laws of the United
          States. Any disputes arising from your use of the Service shall be
          subject to the exclusive jurisdiction of the state and federal courts
          located within the United States.
        </p>
        <br />
        <p>
          <SectionTitle title="8. Changes to Terms and Conditions" />
          PracticePartner.ai reserves the right to modify these Terms and
          Conditions at any time without prior notice. Any changes will be
          posted on our website and will become effective immediately upon
          posting. Your continued use of the Service following the posting of
          changes constitutes your acceptance of those changes.
        </p>
        <br />
        <p>
          <SectionTitle title="9. Contact Information" />
          If you have any questions or concerns regarding these Terms and
          Conditions or our services, please contact us at{" "}
          <a
            href="mailto:Info@PracticePartner.AI"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Info@PracticePartner.AI
          </a>
          .
        </p>
      </section>
    </main>
  );
};

export default TermsofUse;
